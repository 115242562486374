@import url(https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,300;0,400;1,200&family=Poppins:wght@300&display=swap);
.newsBlock {
    display: inline-flex;
    width: 350px;
    margin: 15px;
    text-align: left;
}

img.newsImage,
img.newsTopImage {
    width: 350px;
    height: 200px;
    object-fit: cover;
    transition: 0.3s;
}

/* Not Found start */
.notFound {
    height: 100vh;
    padding-top: 25vh;
}
.pshungary {
    display: inline-flex;
    height: 60px;
}
p.psTitle {
    padding: 0px;
    margin: 0px;
}
.pshungary {
    display: inline-flex;
    height: 60px;
    margin: auto;
    align-content: center;
    align-items: center;
}
.psImage{
    max-height: 30px!important;
}
p.commentCount {
    padding: 4px;
    background: rgba(0,0,0,0.3);
    font-size: 13px;
    border-radius: 3px;
}
.forumsDiv {
    display: inline-flex;
    width: 45%;
    padding: 20px;
    border: 1px solid rgba(0,0,0,0.4);
    margin: 3px;
    justify-content: space-between;
    transition: 0.3s;
}
.forumsDiv:hover{
    background-color: #004f64;
    color: white;
}
.forumsDiv p{
    margin: 0px;
}
.forumsDiv h3{
    margin: 0px;
}
.notFoundImage {
    height: 150px;
}
.forumblock{
    padding:20px;
}

.searchInput {
    padding: 10px;
    padding-top: 11px;
    border: none;
    transition: 0.2s;
    width: 300px;
    text-align: center;
    margin-right: 5px;
    background: none!important;
}
p.youtubeNewf {
    position: absolute;
    top: 272px;
    width: 100%;
    background: rgb(32, 32, 32);
    padding: 5px;
    color: white;
    font-size: 12px;
}
h4.titleyoutube {
    position: absolute;
    top: 240px;
    background: rgb(32,32,32, 0.8);
    color: white;
    width: 100%;
}
.carousel .slide .legend {
    opacity: 0.85 !important;
    font-size: 25px !important;
}
textarea:focus, input:focus, select:focus{
    outline: none;
}
.carousel .slide .legend:hover {
    opacity: 1 !important;
}

.searchInput:focus {
    padding: 10px;
    border-bottom: 2px solid;
}
.theme-dark .searchInput:focus {
    border-bottom: 2px solid white;
    color: white;
}
.theme-dark .searchInput {
    color: white;
}
.theme-light .searchInput:focus {
    border-bottom: 2px solid black;
}
.theme-light a.classic {
    border: 2px solid rgba(0,0,0,0.6);
}
.theme-light a.classic svg.svg-inline--fa.fa-play.toggleDm {
    color: white!important;
}
.searchButton {
    padding: 10px;
    margin: 10px;
    padding-right: 20px;
    padding-left: 20px;
    border: none;
    cursor: pointer;
}

/* Not Found end */
/* ------------ */
/* HomePage start */
.blockHomePage {
    margin: 5px;
    width: 400px;
}

.newsBlock2home {
    width: 400px !important;
}

.marquee {
    width: 100%;
    background-color: #004f64;
    color: white;
    white-space: nowrap;
    overflow: hidden;
    box-sizing: border-box;
    height: 28px;
    margin-bottom: 10px;
}

.articleBigDiv {
    background-color: black;
    height: 470px;
    margin: 5px;
    color: white;
}

.bigArticleImage {
    height: 470px;
}

.imageBig {
    object-fit: cover;
    object-position: top;
}
.comingDiv {
    height: 250px;
    overflow-x: auto;
    overflow-y: hidden;
    display: -webkit-inline-box;
    width: 100%;
    box-shadow:
        1px 1px #373737,
        2px 2px #373737,
        3px 3px #373737,
        4px 4px #373737,
        5px 5px #373737,
        6px 6px #373737;
    transform: translateX(-5px);
}

.comingSoonDiv {
    margin-top: 10px;
    -ms-overflow-style: none;
    scrollbar-width: none;
    overflow: -moz-scrollbars-none;
}
.tagsDiv{
    padding: 40px!important;
    text-align: justify!important;
}
img.forumlogo {
    height: 30px;
}
.tagsDiv a {
    display: inline-block;
    padding: 3px;
    border: 1px solid rgba(0,0,0,0.4);
    margin: 3px;
    font-size: 14px;
    cursor: pointer;
    transition: 0.3s;
}
.tagsDiv a:hover{
    background-color: #004f64;
    color: white;
}
.comingTitle {
    padding: 4px;
    background-color: rgb(32, 32, 32);
    color: white;
    text-align: center;
    margin-bottom: 0px;
    font-size: 13px;
    padding: 5px;
    box-shadow:
        1px 1px #373737,
        2px 2px #373737,
        3px 3px #373737,
        4px 4px #373737,
        5px 5px #373737,
        6px 6px #373737;
    transform: translateX(-5px);
}

.platformtag {
    margin: 5px;
    padding: 5px;
    display: inline-block;
    border-radius: 5px;
    border: 1px solid white;
    font-size: 13px;
}

.image_wrapper {
    position: relative;
    height: 300px;
    width: 350px;
}

.imgOverlay {
    height: 250px;
    width: 350px;
    object-fit: cover;
}

.overlay1 {
    position: absolute;
    color: white;
    padding-top: 20px;
}

.overlay_0 {
    left: 0;
    top: 0;
    height: 250px;
    width: 350px;
    background: rgba(0, 0, 0, 0.4);
    -webkit-backdrop-filter: blur(3px);
            backdrop-filter: blur(3px);
}

.comingGame {
    width: 350px !important;
    height: 250px;
    color: white;
    padding: 10px;
    background-image: url("https://picsum.photos/350/400?blur=5");
}

.comingGameDate {
    background: white;
    color: black;
    display: inline;
    padding: 5px;
    margin: 0px;
    border-radius: 5px;
    font-size: 14px;
}

.comingGameTitle {
    padding: 15px;
    margin: 0 !important;
    font-size: 20px;
}

p.comingGameDay {
    margin-bottom: 6px;
}

.comingSoonDiv::-webkit-scrollbar {
    display: none;
}

.marquee p {
    display: inline-block;
    padding-left: 100%;
    animation: marquee 120s linear infinite;
    font-size: 13px
}

@keyframes marquee {
    0% {
        transform: translate(0, 0);
    }

    100% {
        transform: translate(-100%, 0);
    }
}

/* HomePage end */


.container {
    width: 350px;
    height: 200px;
    position: relative;

}


/* Footer start */
.topFooter {
    background-color: rgb(32, 32, 32);
}

.footer {
    background: black;
    margin: auto;
    font-size: 13px;
    color: white;
}

p.adaptive {
    margin: 0px;
    padding-bottom: 10px !important;
}

img.adaptivlo {
    padding: 10px;
}

.adaptive {
    padding: 0px !important;
    line-height: normal;
    text-align: center;
}

.adatp {
    padding-top: 5px;
}

/* Footer end */
/* ------------ */

.theme-light {
    color: rgb(25, 25, 25) !important;
    background: white;
}

.theme-dark .subline>p {
    background-color: rgba(0, 0, 0, 0.8);
    color: white;
}


.theme-white .subline>p {
    background-color: rgba(255, 255, 255, 0.8);
    color: black;
}

.theme-dark {
    color: white !important;
    background: rgb(25, 25, 25);
}

.fullwidth {
    width: 45% !important;
}

.box {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0.9;
}

.overlay {
    z-index: 9;
    background: rgb(0,0,0);
background: linear-gradient(0deg, rgba(0,0,0,1) 18%, rgba(0,0,0,0) 100%);
    transition: 0.3s;
}

.newsTitle,
.newsTopTitle {
    text-align: left;
    margin-top: 10px;
    font-size: 1.25rem;
    line-height: 1.75rem;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    line-clamp: 2;
    -webkit-box-orient: vertical;
}

.newsTopTitle>h5 {
    margin: 0px !important;
}

.subline>p {
    padding: 0px;
    margin: 0px;
    font-size: 12px;
    background-color: rgba(255, 255, 255, 0.8);
    display: inline;
    padding: 3px;
    color: black;
}
.message {
    font-size: 19px!important;
}

.newsTopInfos {
    position: absolute;
    bottom: 0;
    padding: 10px;
}

.newsTopTitle {
    color: white !important;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    font-family: "Rubik", sans-serif;
    margin: 0 0 1.25rem 0;
    font-weight: 600;
    line-height: 30px;
}

.additionalText {
    font-size: 13px;
    margin-top: 10px;
    text-align: left;
    margin-right: 15px;
    display: inline-flex;
}

.textAuthor {
    margin: 0;
    margin-right: 15px;
    display: inline-block;
}

.faIcon {
    font-size: 13px;
    margin-right: 4px;
}

.newsInfos {
    background-color: white;
    position: relative;
    padding: 10px;
    padding-top: 3px;
    padding-bottom: 18px;

    top: -30px;
}


.newsBlock {
    transition: .5s ease;
    margin-bottom: 0px;
}

.newsBlock:hover {
    box-shadow:
        1px 1px #373737,
        2px 2px #373737,
        3px 3px #373737,
        4px 4px #373737,
        5px 5px #373737,
        6px 6px #373737;
    transform: translateX(-3px);
    transition: .5s ease;
}

.newsBlock:hover .overlay {
    background-color: rgba(0, 0, 0, 0);
}

.HomePageBlock {
    max-width: 1230px;
    text-align: center;
    margin: auto;
}

.newsBlock1 {
    height: 290px;
    cursor: pointer;
    margin-bottom: 10px;
}

.newsBlock2 {
    height: 200px;
    cursor: pointer;
    background-color: black;
}

.ribbon-2 {
    position: absolute;
    padding: 10px;
    background: #BD1550;
    font-size: 12px;
    margin: 10px;
    color: white;
    font-weight: 600;
    z-index: 15;
}

.topof {
    position: absolute;
    width: 350px;
    height: 200px;
    z-index: 11;
    color: white;
}

.bodys {
    width: 50%;
    margin: auto;
}

.block {
    margin: auto;
}

.toggleDm {
    background: none;
    border: none !important;
    font-size: 25px;
    cursor: pointer;
    color: white !important;
    width: 30px;
}

.contentBody {
    width: 1100px;
    margin: auto;
    margin-top: 30px;
    text-align: justify;
    padding: 20px;
    transition: 0.3s;
    display: flex;
}
.contentone {
    position: -webkit-sticky;
    position: sticky;
    top: 100px;
}
.divone {
    flex: 75% 1;
    padding: 10px;
    margin-right: 10px;
}
.contentone ul {
    margin: 16px;
}
.divtwo{
    flex: 25% 1;
    background-color: rgba(0,0,0,0.1);
    padding: 10px;
}
.stickySidebar{
    position: -webkit-sticky;
    position: sticky;
    top: 75px;
}
.review{
    display: inline-flex;
    width: 100%;
}
.thirdDiv p {
    margin: 0px;
    font-size: 14px;
}
p.starRating {
    font-size: 60px;
    color: rgb(251 180 0);
    text-shadow: 0 -1px 0 #fff, 0 1px 0 #2e2e2e, 0 2px 0 #2c2c2c, 0 3px 0 #2a2a2a, 0 4px 0 #282828, 0 5px 0 #262626, 0 6px 0 #242424, 0 7px 0 #222, 0 8px 0 #202020, 0 9px 0 #1e1e1e, 0 10px 0 #1c1c1c, 0 11px 0 #1a1a1a, 0 12px 0 #181818, 0 13px 0 #161616, 0 14px 0 #141414, 0 15px 0 #121212, 0 22px 30px rgb(0 0 0 / 90%);
}
h2.starTitle {
    text-shadow: 1px 0px 1px #cccccc, 0px 1px 1px #eeeeee, 2px 1px 1px #cccccc, 1px 2px 1px #eeeeee, 3px 2px 1px #cccccc, 2px 3px 1px #eeeeee, 4px 3px 1px #cccccc, 3px 4px 1px #eeeeee, 5px 4px 1px #cccccc, 4px 5px 1px #eeeeee, 6px 5px 1px #cccccc, 5px 6px 1px #eeeeee, 7px 6px 1px #cccccc;
    font-size: 45px;
}

.indieReview {
    background: rgba(0,0,0,0.3);
    padding: 50px;
    position: relative;
    box-shadow: 0 -1px 0 #fff, 0 1px 0 #2e2e2e, 0 2px 0 #2c2c2c, 0 3px 0 #2a2a2a, 0 4px 0 #282828, 0 5px 0 #262626, 0 6px 0 #242424, 0 7px 0 #222, 0 8px 0 #202020, 0 9px 0 #1e1e1e, 0 10px 0 #1c1c1c, 0 11px 0 #1a1a1a, 0 12px 0 #181818, 0 13px 0 #161616, 0 14px 0 #141414, 0 15px 0 #121212, 0 22px 30px rgb(0 0 0 / 90%);
}
.image-bg, .image-bg-review {
    opacity: 0.4;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 290px;
    padding: 20px;
    object-fit: cover;
}
.image-bg-review{
    height: 100%;
}
  
.reviewAll{
    margin: auto;
    width: 1100px;
    margin-bottom: 50px;
    padding: 20px;
    position: relative;
}
.reviewAll h2{
    color: black;
}
.theme-dark .header{
    background: rgb(32,32,32);
}
.theme-light .header{
    background: rgb(255,255,255);
}
.theme-light .headerLogo {
    filter: brightness(0);
}
.theme-dark .headerLogo{
    filter: brightness(1);
}
.theme-light .psImage {
    filter: brightness(0);
}
.theme-dark .psImage{
    filter: brightness(1);
}
.theme-dark .header__content, .theme-dark .header__content svg{
    color: white!important;
    text-transform: uppercase;
    font-weight: bold;
}
.theme-light .header__content svg, .theme-light .header__content{
    color: rgb(32,32,32)!important;
    text-transform: uppercase;
    font-weight: bold;
}
.reviewBg{
    padding: 20px;
    position: relative;
    background-color: rgba(0,0,0,0.4);
    box-shadow: 0 -1px 0 #fff, 0 1px 0 #2e2e2e, 0 2px 0 #2c2c2c, 0 3px 0 #2a2a2a, 0 4px 0 #282828, 0 5px 0 #262626, 0 6px 0 #242424, 0 7px 0 #222, 0 8px 0 #202020, 0 9px 0 #1e1e1e, 0 10px 0 #1c1c1c, 0 11px 0 #1a1a1a, 0 12px 0 #181818, 0 13px 0 #161616, 0 14px 0 #141414, 0 15px 0 #121212, 0 22px 30px rgb(0 0 0 / 90%);

}
.firstDiv, .secondDiv{
    flex: 35% 1;
    padding: 15px;
    color: white;
}
img.bulletIcon {
    height: 15px;
    margin: 1px;
}
p.gyengesegek, p.erossegek {
    font-size: 14px;
    text-align: start;
}
.thirdDiv{
    flex: 30% 1;
    margin: 15px;
    padding: 30px;
    background-color: rgba(0,0,0,0.45);
    border-radius: 15px 50px 30px;
    border: 2px dotted grey;
    box-shadow: 6px 7px 17px -4px #000000;
    color: white;
}
.thirdDiv h3{
    font-size: 40px;
    padding: 5px;
}
.divtwo a{
    color:rgb(25, 25, 25) !important;
}
.divtwo .sidebarTitle {
    background-color: rgba(25,25,25,0.7);
    padding: 10px;
    color:white !important;
}
.theme-light .divtwo{
    color:rgb(25, 25, 25) !important;
}
.theme-light .divtwo a{
    color:rgb(25, 25, 25) !important;
}
.theme-dark .divtwo{
    color:white !important;
}
.theme-dark .divtwo a{
    color:white !important;
}
.divtwo h4 {
    margin: 0px!important;
    line-height: normal;
    
}
.divtwo p {
    margin: 0px!important;
    font-size: 13px;
}
.divtwo .sidebarArticle{
    padding: 5px;
}

.contentBody img {
    width: 100%;
    object-fit: contain;
    height: auto;
    max-height: 450px;
}

p {
    margin-bottom: 20px;
}

.content-image {
    width: 100%;
    height: 450px;
    object-fit: cover;
}

#container {
    width: 100%;
    height: 450px;
    position: relative;
}

.additionalTextContent {
    margin-right: 0px;
    display: block;
    text-align: center;
}
.additionalTextContent p {
    margin: 0px;
}

#container hr {
    width: 150px;
    margin: 20px;
    margin-bottom: 0px;
}

#navi,
#infoi {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
}

h1 {
    margin: 30px !important;
}

h2 {
    margin: 10px !important;
}

hr {
    margin: 15px;
    width: 100px;

}

.comments {
    width: 50%;
    background-color: rgba(0, 0, 0, 0.1);
    padding: 20px;
    border-radius: 10px;
}

#infoi {
    z-index: 10;
    margin: auto;
    padding-top: 80px;
    color: white;
    background: rgba(0, 0, 0, 0.4);
    -webkit-backdrop-filter: blur(5px);
            backdrop-filter: blur(5px);
}

.commentAvatar {
    height: 50px;
    width: 50px;
    object-fit: cover;
    border-radius: 10px;
}

.commentsName {
    font-size: 13px;
}

.commentDiv {
    display: inline-flex;
    width: 100%;
    margin: 5px;
}

.commentContent {
    padding: 8px;
    font-size: 13px;
    border: 1px solid rgba(0, 0, 0, 0.2);
    border-radius: 5px;
    margin-left: 5px;
    background-color: rgba(255, 255, 255, 0.4);
    width: 90%;
    text-align: justify;
    height: auto;
    word-wrap: break-word;
}

.paginationButton {
    padding: 5px;
    margin-left: 19px;
    margin-right: 15px;
    border: none;
    transition: 0.3s;
    cursor: pointer;
    padding-left: 10px;
    padding-right: 10px;
    font-size: 15px;
    border-radius: 5px;
    background: none;
}

.theme-dark .paginationButton {
    color: white !important;
}

.theme-light .paginationButton {
    color: black !important;
}

.paginationButton:hover {
    background-color: black;
    color: white;
}

.paginationDiv {
    text-align: center;
}

.authorMeta {
    padding: 0px;
    margin: 0px;
    font-size: 12px;
    font-weight: 600;
}

.commentMeta {
    width: 50px;
}
.hirdetes{
    height: 250px;
    background-color: black;
    margin: 5px;
    color: white;
}
@media only screen and (max-width: 1100px) {
    .newsTitleBox {
        font-size: 30px!important;
    }
    p.tagTitle {
        opacity: 0.95!important;
        height: 38px!important;
    }
.contentBody {
    display: block!important;
    width: auto;
}
}
@media only screen and (max-width: 760px) {
    .gamerTechImageDiv {
        height: 68px!important;
    }
    #navi,
#infoi {
    width: 100%; 
}
#infoi {
    padding-top: 15px!important;
}
.theme-light .header__content svg, .theme-light .header__content{
    color: rgb(255,255,255)!important;
}
.theme-light .header__content__toggle svg{
    color: rgba(32,32,32)!important;
}
#container {
    min-height: 350px!important;
}
    .newscontento{
        min-height: 350px!important;   
       }
    .additionalTextHome{
        display: block;
    }
    .marquee{
        margin-bottom: 0px;

    }
    .nomob{
        display: none;
    }
    .homepageArticles{
        display: block!important;
    }
    .HomePageBlock {
        max-width: 100%;
        text-align: center;
        margin: auto;
    }

    .comingSoonDiv {
        width: 100%;
    }

    .articleBigDiv {
        width: 100%;
        margin: 0px;
    }

    .newsBlock2home {
        width: 350px !important;
    }

    .notFound {
        padding: 30px;
        height: auto;
    }

    .searchInput {
        text-align: center;
    }
    .blockContent{
        margin-top: 70px!important;
    }
    .content-image {
        width: 100%;
        height: 450px;
    }

    .contentBody {
        width: 100%;
        padding: 10px;
        padding-top: 0px;
        text-align: justify;
    }

    img {
        max-width: 100%;
    }

    iframe {
        max-width: 100%;
    }
    .tags {
        width: 100%!important;
        
    }
    iframe.wp-embedded-content {
        display: none;
    }
    p {
        margin-bottom: 20px;
    }

    h1 {
        text-align: center;
    }
    
    .comments {
        width: 90%;
    }
    .recentAndAd{
        display: block!important;
    }
    .adDiv{
        margin: 0px!important;
    }
    .profileDiv {
        width: auto!important;
    }
    .twocolumn{
        display: block!important;
    }
    .bgNewsBlockHome{
        width: 97% !important;
        min-height: 200px!important;
      }
      .youtubeAndArt{
        display: block!important;
        height: auto!important;
    }
    .youtube {
        height: 270px!important;
        margin-bottom: 10px!important;
    }
    .tagDiv {
        flex: 100% 1!important;
        height:auto!important;
        margin-left: 0px!important;
    }
    .newtagdiv {
        display: block!important;
        margin-bottom: 10px;
    }
    .imgtag{
        height: 38px!important;
    }
    .discord{
        width: 100%!important;
        height: auto!important;
    }
    .tagDiv{
        min-height: 34px!important;
    }
    .disrcordImage{
        max-height: 30px!important;
    }
    p.discordTitle {
        font-size: 15px!important;
    }
    h3.newsHomeTitle {
        line-height: normal;
        font-size: 16px;
        margin-bottom: 5px;
    }
    h5.newsHomeTitle {
        line-height: normal;
        font-size: 12px;
    }
    .ios{
        display: none!important;
    }
    .android{
        display: none!important;
    }
    .header{
        height: 70px!important;
    }
    main{
        margin-top: 70px!important;
    }
    .newsBlockHome:hover{
        box-shadow: none !important;
        transform: translateX(0px)!important;
    }
    .reviewAll{
        width:auto!important;
    }
    .review{
        display: block!important;
    }
}
.block{
    padding-top: 20px;
    padding-bottom: 20px;
}
.timein{
    display: inline-block;
    padding-right: 10px;
}
main {
    margin-top: 70px;
}
.tiled-gallery__item>a,
.tiled-gallery__item>a>img,
.tiled-gallery__item>img {
    display: block;
    height: auto;
    margin: 0;
    max-width: 100%;
    object-fit: cover;
    object-position: center;
    padding: 0;
    width: 100%;
}

.wp-block-jetpack-tiled-gallery.is-style-columns .tiled-gallery__item,
.wp-block-jetpack-tiled-gallery.is-style-rectangular .tiled-gallery__item {
    display: flex;
}

#gp-review-content-wrapper .gp-subtitle,
#gp-review-content-wrapper .gp-share-icons,
#gp-review-content-wrapper .gp-entry-text>*:not(.wp-caption):not(img):not(.vc_row):not(.vc_row-full-width),
#gp-review-content-wrapper .gp-entry-tags,
#gp-review-content-wrapper #gp-share-icons {
    width: 80%;
    margin-left: auto;
    margin-right: auto;
}

.tiled-gallery__row {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin: 0;
}

.tiled-gallery__col {
    display: inline-grid;
    flex-direction: column;
    margin: 0;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
}
.tags {
    width: 1100px;
    margin: auto;
    padding: 15px;
}
.tiled-gallery__item {
    justify-content: center;
    overflow: hidden;
    padding: 0;
    position: relative;
    margin: 5px;
    border-radius: 5px;
}
.discordin {
    text-align: center;
    background: #5865f2;
    background: linear-gradient(90deg,#5865f2,#444daf);
    margin-bottom: 10px;
    padding: 10px;
}
p.discordTitlein {
    margin: auto;
    text-transform: uppercase;
    text-align: center;
    font-size: 15px;
    color: #fff;
    /* padding: 5px; */
    font-weight: bolder;
}
img.disrcordImagein {
    width: 60px;
}
.imgtag{
    height: 133px;
    object-fit: cover;
}
svg.svg-inline--fa.fa-play.toggleDm {
    color: white!important;
}
img.socialbutton {
    max-height: 25px;
}
.socialbutton:hover {
    background: none!important;
}
li.socialli {
    margin-top: 5px;
}
a.sociallink {
    margin: 0!important;
    padding: 7px!important;
}
.tiled-gallery__gallery {
    width: 100%;
    display: flex;
    padding: 0;
    flex-wrap: wrap;
}

/* Carousel start */

.additionalText.carouselAd {
    position: absolute;
    top: 0;
    right: 0;
    background: rgba(0, 0, 0, 0.7);
    padding: 10px;
}

.carousel-status {
    display: none;
}
.homepageArticles{
    display:flex;
}

.column1{
    flex: 65% 1;
}
.column2{
    flex: 35% 1;
}
.newsBlockHome, .articlesBlockHome{
    padding: 5px;
    display:flex;
    transition: 0.3s;
    min-height: 200px;
    margin-bottom: 5px;
    background: rgba(0,0,0,0);
}
.bgNewsBlockHome{
  height: 200px;
  width: 790px;
  position: absolute;
  opacity: 0.2;
  filter: grayscale(100%);
}
.bgNewsBlockHome:hover{
  filter: grayscale(0%)
}
.articlesBlockHome{
    display:block;
}
.newsColumn-1{
    flex: 30% 1;
    height: 200px;
    z-index: 5;
}
.newsImage{
    width: 30%;
    height: 300px;
}
.newsColumn-2{
    flex: 70% 1;
    text-align: left;
    padding: 15px;
    padding-bottom: 0px;
}
.newsHomeTitle{
    margin: 0;
}
.newsBlockHome:hover{
    box-shadow:
        1px 1px #373737,
        2px 2px #373737,
        3px 3px #373737,
        4px 4px #373737,
        5px 5px #373737,
        6px 6px #373737;
    transform: translateX(-5px);
}
.theme-dark .newsBlockHome:hover{
    background-color: black;
    box-shadow:
        1px 1px #373737,
        2px 2px #373737,
        3px 3px #373737,
        4px 4px #373737,
        5px 5px #373737,
        6px 6px #373737;
    transform: translateX(-5px);
}
.artoBlockHome{
    width:100%;
    transition: 0.3s;
}
.artoBlockHome:hover{
    box-shadow: 0px 1px 19px -6px rgba(0,0,0,0.75);
-webkit-box-shadow: 0px 1px 19px -6px rgba(0,0,0,0.75);
-moz-box-shadow: 0px 1px 19px -6px rgba(0,0,0,0.75);
}
.topofHome {
    position: absolute;
    min-width: 420px;
    height: 200px;
    z-index: 11;
    color: white;
    text-align: left;
}
.containerHome {
    min-width: 420px;
    height: 200px;
    position: relative;
}
.boxHome {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
}


.overlayHome {
    z-index: 9;
    transition: 0.3s;
}
img.newsImageHome {
    width: 420px;
    height: 200px;
    object-fit: cover;
    transition: 0.3s;
}
.newsTopInfosHome{
    background-color: rgba(0,0,0,0.5);
    width: 100%;
    transition: 0.5s;
}
.newsTopInfosHome:hover{
    background-color: rgba(0,0,0,0.9);
}
#comments{
    margin-bottom: 20px;
}
.previousComment>p {
    margin-bottom: 5px;
    opacity: 0.8;
    border: 1px solid rgba(0,0,0,0.5);
    padding: 5px;
    background-color: rgba(255,255,255,0.2);
    border-radius: 5px;
    display: inline-block;
    font-style: italic;
    font-size: 12px;
}
.previousComment>p::before {
    position: relative;
    content: "\201D";
    right: 0px;
    top:0px;
}
/* css */
html {
    scroll-behavior: smooth;
}
.commentsNews{
    cursor: pointer;
}
.previousComment>p::after {
    position: relative;
    content: "\201D";
    right: 0px;
    top:0px;
}
.commentDiv.reply {
    padding-left: 50px;
}

/* Recent comment start */
.recentCommentsContent{
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    text-align: left;
    width: 90%;
}
.recentCommentsImage{
    height: 40px!important;
    width: 40px!important;
}
.recentCommentAuthor{
    height: 40px!important;
    width: 40px!important;
}
.recentDate{
    font-size: 11px!important;
    opacity: 0.4;
}
.recentCommentDiv{
    display: flex;
    transition: 0.2s;
    margin:5px;
}
.recentAndAd{
    display: flex;
}
.recentCo{
    flex: 75% 1;
    background: #2a2a2a;
    color: white;
    padding: 10px;
    margin: 5px;
}
.adDiv{
    flex: 25% 1;
    width: 100%;
    background-color: black;
    margin: 5px;
}
.recentCommentsContent p {
    margin: 0px;
    font-size: 14px;
    margin-left: 5px;
}
.recentCommentDiv:hover{
    background-color: #3a3a3a;
}
.adDiv{
    color: white;
}

.loginInput{
    margin-bottom: 10px;
    border: 1px solid black;
    background: rgba(255,255,255,0.5)!important;
  }
  .gamerTechImageDiv {
    padding: 0!important;
    height: 150px;
    margin: 5px;
}
.loginInput:hover{
    background-color: white;
  }
.loginScreen{
    height: 100vh;
    padding-top: 150px;
  }
textarea.commentBox{
    max-width: 100%;
    min-width: 100%;
    padding: 10px;
    border: none;
    opacity: 0.5;
    
}
textarea.commentBox:focus, textarea.commentBox:hover{
    opacity: 1;
}
.commentBlock{
    padding:10px;
    margin: 10px;
}
/* Loading start */
.lds-roller {
    display: inline-block;
    position: relative;
    width: 80px;
    height: 80px;
  }
  .lds-roller div {
    animation: lds-roller 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
    transform-origin: 40px 40px;
  }
  .lds-roller div:after {
    content: " ";
    display: block;
    position: absolute;
    width: 7px;
    height: 7px;
    border-radius: 50%;
    background: #fff;
    margin: -4px 0 0 -4px;
  }
  .lds-roller div:nth-child(1) {
    animation-delay: -0.036s;
  }
  .lds-roller div:nth-child(1):after {
    top: 63px;
    left: 63px;
  }
  .lds-roller div:nth-child(2) {
    animation-delay: -0.072s;
  }
  .lds-roller div:nth-child(2):after {
    top: 68px;
    left: 56px;
  }
  .lds-roller div:nth-child(3) {
    animation-delay: -0.108s;
  }
  .lds-roller div:nth-child(3):after {
    top: 71px;
    left: 48px;
  }
  .lds-roller div:nth-child(4) {
    animation-delay: -0.144s;
  }
  .lds-roller div:nth-child(4):after {
    top: 72px;
    left: 40px;
  }
  .lds-roller div:nth-child(5) {
    animation-delay: -0.18s;
  }
  .lds-roller div:nth-child(5):after {
    top: 71px;
    left: 32px;
  }
  .lds-roller div:nth-child(6) {
    animation-delay: -0.216s;
  }
  .lds-roller div:nth-child(6):after {
    top: 68px;
    left: 24px;
  }
  .lds-roller div:nth-child(7) {
    animation-delay: -0.252s;
  }
  .lds-roller div:nth-child(7):after {
    top: 63px;
    left: 17px;
  }
  .lds-roller div:nth-child(8) {
    animation-delay: -0.288s;
  }
  .lds-roller div:nth-child(8):after {
    top: 56px;
    left: 12px;
  }
  @keyframes lds-roller {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
.contentone a {
    color: #3C84AB;
    font-weight: 600;
    word-wrap: break-word;
}
select.selectedCategory {
    padding: 10px;
    border-radius: 20px;
    margin:5px;
    border:none;
}
.replycontent {
    text-align: right;
}
.replycontent a {
    cursor: pointer;
}
.contentone a:hover {
    background: #3C84AB;
    color: white;
}
h6.subtitle {
    margin-bottom: 0;
}
.blockContent h1{
    margin-bottom:10px!important;
}
.platforms {
    margin-bottom: 15px;
}
.newscontento{
 height: 250px!important;   
}
.newscontento #infoi {
    padding-top:15px!important;
}
.header {
    position: fixed;
    top: 0;
    z-index: 20;
}
.blockContent {
    margin-top: 70px;
}
figure.aligncenter.size-large img{
    width: 100%!important;
    max-height: 100%;
}
.tags p{
    margin: 0px;
    font-size: 13px;
}
.tag {
    font-size: 13px;
    padding-left: 3px;
    padding-right: 3px;
    border: 1px solid rgba(25,25,25,0.5);
    border-radius: 5px;
    margin-left: 5px;
    display: inline-block;
}
.profile {
    min-height: 100vh;
}
.profileHeader{
    width: 100%;
    height: 250px;
    object-fit: cover;
}
.ql-editor {
    overflow-y: scroll;
    resize: vertical;
    min-height: 150px;
    background-color: rgba(255,255,255,0.3);
  }
.ql-editor:hover{
    background-color: rgba(255,255,255,0.4);
}
.ql-editor:focus{
    background-color: rgba(255,255,255,0.5);
}
div#playstationcomm_nyito_pc_300x600_1 {
    margin: 5px;
}
.ql-editor {
    overflow-y: scroll;
    resize: vertical;
    min-height: 150px;
    background-color: rgba(255,255,255,0.3);
  }
  .theme-dark .ql-editor{
    background-color: rgba(0,0,0,0.3);
}
.theme-dark .commentContent{
    background-color: rgba(0,0,0,0.3);
}
.theme-dark .ql-editor:hover{
    background-color: rgba(0,0,0,0.4);
}
.theme-dark .ql-container.ql-snow {
    border: 1px solid rgba(0,0,0,0.7);
}
.theme-dark .ql-editor:focus{
    background-color: rgba(0,0,0,0.5);
}
.theme-dark .ql-toolbar.ql-snow {
    border: none!important;
    border-bottom: 1px solid rgba(0,0,0,0.7)!important;
}
.commentBlock button {
    padding: 5px;
    margin: 5px;
    cursor: pointer;
    border-radius: 15px;
    border: none;
    width: 200px;
}
.profileDiv {
    position: relative;
    top: -70px;
    width: 1100px;
    margin: auto;
}
img.authorNewsimage {
    height: 50px;
    width: 20%;
    object-fit: cover;
}
.ql-toolbar.ql-snow {
    border: none!important;
    border-bottom: 1px solid #ccc!important;
}
.half{
    flex:50% 1;
    text-align: left;
    font-size: 14px;
    padding: 15px;
}
.twocolumn {
    display: flex;
}
img.avatarImg {
    width: 200px;
    height: 200px;
    border-radius: 100px;
}
.newsdiv{
    height: 60px;
    display: inline-flex;
    margin-top:5px;
    width:100%
}
.newsdiv h5{
    padding-left: 5px;
    margin: 0;
    font-size: 12px;
}
.newsdiv p{
    padding-left: 5px;
    margin: 0;
    font-size: 12px;
}
h4.title {
    margin: 0;
    background: black;
    color: white;
    text-align: center;
}
img.authorAvatar {
    width: 40px;
    height: 40px;
    border-radius: 20px;
}
p.consoletag {
    display: inline-block;
    padding: 5px;
    margin: 3px;
    border-radius: 5px;
    border: 1px solid black;
    font-size: 13px;
}
p.youtubeNew {
    background: rgb(147, 0, 0);
    padding: 5px;
    color: white;
    display: block;
    position: absolute;
    margin: 10px;
    font-size: 13px;
    font-weight: 800;
}

.youtubeAndArt{
    display: flex;
    height: 270px;
    margin: 5px;
}
.youtube{
    flex: 40% 1;
    background-color: black;
}
.articlebyTag{
    flex: 60% 1;

}
#containeryt {
    width: 100%;
    height: 100%;
    position: relative;
  }
  #naviyt,
  #infoiyt {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    transition: 0.3s
  }
  #infoiyt {
    z-index: 10;
  }
  img.youtubeima {
    max-height: 79px;
    margin-top: 20%;
    opacity: 0;
    transition: 0.3s;
}
.youtube:hover img.youtubeima{
    opacity: 1;
}
.youtube:hover #naviyt{
    opacity: 0.3;
}
.tagDiv {
    flex: 30% 1;
    min-height: 133px;
    margin-left: 5px;
    margin-bottom: 4px;
}
.newtagdiv {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
}
.title {
    display: flex;
    width: 100%;
    background: black;
    height: 30px;
    justify-content: space-between;
}
.title button{
    background: none;
    border:none;
    color: white;
    cursor: pointer;
    margin-left: 5px;
    margin-right: 5px;
}
#containertag {
    width: 100%;
    height: 100%;
    position: relative;
  }
  #navitag,
  #infoitag {
    width: 100%;
    height: 100%;
    position: absolute;

    transition: 0.3s
  }
  #infoitag {
    z-index: 10;
  }
  p.tagTitle {
    background: rgba(0,0,0,0.9);
    padding: 10px;
    -webkit-backdrop-filter: blur(0px);
            backdrop-filter: blur(0px);
    color: white;
    font-weight: 600;
    font-size: 12px;
    opacity: 0.5;
    height: 50px;
    transition: 0.3s;
    display: flex;
    align-items: center;
    justify-content: center;
    vertical-align: middle;
}
#infoitag:hover > p.tagTitle {
    opacity: 1;
    height: 100%;
    vertical-align: middle;
  }
.discord {
    height: 60px;
    background: rgb(88,101,242);
    background: linear-gradient(90deg, rgba(88,101,242,1) 0%, rgba(68,77,175,1) 100%);
    margin-top: 0px;
    display: inline-flex;
    width: 32.5%;
    cursor: pointer;
}
.android{
    background: #134E5E;  /* fallback for old browsers */  /* Chrome 10-25, Safari 5.1-6 */
    background: linear-gradient(to right, #71B280, #134E5E); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
    margin-left: 10px;
    margin-right: 10px;
}
.ios {
    background: #16222A;  /* fallback for old browsers */  /* Chrome 10-25, Safari 5.1-6 */
background: linear-gradient(to right, #3A6073, #16222A); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */

}
p.discordTitle {
    margin: auto;
    text-transform: uppercase;
    text-align: end;
    font-size: 17px;
    color: white;
    padding: 5px;
    font-weight: bolder;
}
.discord:hover .disrcordImage{
    margin: 15px;
    transform: rotate(-8deg)
}
p.searchedtext {
    margin: 5px;
}
.disrcordImage{
    max-height: 70px;
    margin: 15px;
    transition: 0.3s
}
div#searchs button {
    border: none;
    border-radius: 20px;
    background: rgba(255,255,255,0.3);
    transition: 0.3s;
    padding: 5px;
    font-size: 12px;
    cursor: pointer;
}
div#searchs button:hover {
    background: rgba(255,255,255,1);
}
.recentCommentsContent p {
    font-weight: normal;
}
.stickySidebar h6 {
    margin: 0;
}
video {
    max-width: 95%;
}
.wp-block-embed__wrapper {
    margin: 5px;
}
.game {
    position: relative;
    width: 800px;
    height: 500px;
    border: 1px solid black;
  }
  
  .player {
    position: absolute;
    width: 50px;
    height: 50px;
    background-color: blue;
  }
  
  .obstacle {
    position: absolute;
    width: 100px;
    height: 50px;
    background-color: red;
  }
  
  .score {
    position: absolute;
    top: 10px;
    right: 10px;
    font-size: 24px;
    font-weight: bold;
  }
  p.prevAuthor {
    margin: 3px;
    font-size: 10px;
}
.header{ 
    box-shadow: -1px 11px 23px -10px rgba(0,0,0,0.58);
}
#myImg {

    border-radius: 5px;
    cursor: pointer;
    transition: 0.3s;
  }
  
  #myImg:hover {opacity: 0.7;}
  
  
  #myImg2 {
  
    border-radius: 5px;
    cursor: pointer;
    transition: 0.3s;
  }
  
  #myImg2:hover {opacity: 0.7;}
  
  /* The Modal (background) */
  .modal {
    display: none; /* Hidden by default */
    position: fixed; /* Stay in place */
    z-index: 100; /* Sit on top */
    padding-top: 100px; /* Location of the box */
    left: 0;
    top: 0;
    width: 100%; /* Full width */
    height: 100%; /* Full height */
    overflow: hidden; /* Enable scroll if needed */
    background-color: rgb(0,0,0); /* Fallback color */
    background-color: rgba(0,0,0,0.9); /* Black w/ opacity */
  }
  
  /* Modal Content (image) */
  .modal-content {
    margin: auto;
    display: block;
    width: 90%;
    max-height: 90%;
    object-fit: contain;
  }
  
  /* Caption of Modal Image */
  #caption {
    margin: auto;
    display: block;
    width: 80%;
    max-width: 700px;
    text-align: center;
    color: #ccc;
    padding: 10px 0;
    height: 150px;
  }
  
  /* Add Animation */
  .modal-content, #caption {  
    -webkit-animation-name: zoom;
    -webkit-animation-duration: 0.6s;
    animation-name: zoom;
    animation-duration: 0.6s;
  }
  
  @keyframes zoom {
    from {transform:scale(0)} 
    to {transform:scale(1)}
  }
  
  /* The Close Button */
  .close {
    position: absolute;
    top: 15px;
    right: 35px;
    color: #f1f1f1;
    font-size: 40px;
    font-weight: bold;
    transition: 0.3s;
  }
  
  .close:hover,
  .close:focus {
    color: #bbb;
    text-decoration: none;
    cursor: pointer;
  }
  
  /* 100% Image Width on Smaller Screens */
  @media only screen and (max-width: 700px){
    .modal-content {
      width: 100%;
    }
  }
  p.has-text-align-center {
    text-align: center;
}
a.classic {
    padding: 5px!important;
    font-size: 13px;
    border: 2px solid rgba(255,255,255,0.5);
    font-weight: 900;
}
svg.svg-inline--fa.fa-play.toggleDm {
    padding: 0px;
    font-size: 13px;
    margin: 0px;
    color: white;
    background: #FF0000!important;
    padding: 2px;
    border-radius: 3px;
    position: relative;
    bottom: -2px!important;
}
p.favbutton {
    font-size: 13px;
    padding: 8px;
    border: 1px solid gray;
    display: inline-block;
    border-radius: 5px;
    transition: 0.3s;
    cursor: pointer;
    margin-right: 5px;
}
p.favbutton:hover {
    background: rgb(97, 0, 0);
    color: white;
    border: 1px solid rgb(147, 0, 0);
}

a.favbutton {
    font-size: 13px;
    padding: 8px;
    border: 1px solid gray;
    display: inline-block;
    border-radius: 5px;
    transition: 0.3s;
    cursor: pointer;
}
a.favbutton:hover {
    background: black;
    color: white;
}
blockquote.twitter-tweet {
    display: inline-block;
    font-family: "Helvetica Neue", Roboto, "Segoe UI", Calibri, sans-serif;
    font-size: 12px;
    font-weight: bold;
    line-height: 16px;
    border-color: #eee #ddd #bbb;
    border-radius: 5px;
    border-style: solid;
    border-width: 1px;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.15);
    margin: 10px 5px;
    padding: 0 16px 16px 16px;
    max-width: 468px;
  }
  
  blockquote.twitter-tweet p {
    font-size: 16px;
    font-weight: normal;
    line-height: 20px;
  }
  
  blockquote.twitter-tweet a {
    color: inherit;
    font-weight: normal;
    text-decoration: none;
    outline: 0 none;
  }
  
  blockquote.twitter-tweet a:hover,
  blockquote.twitter-tweet a:focus {
    text-decoration: underline;
  }
.newsTitleBox {
    font-family: 'Work Sans', sans-serif;
    font-size: 1.6vw;
    font-weight: 900;
  top: 50%;
  left: 50%;
  text-align: justify;
  text-transform: uppercase;
  background-image: linear-gradient(#004f64, #004254);
  background-size: 20px 100%;
  background-repeat: no-repeat;
  background-position: 100% 100%;
  padding-left:10px;
  padding: 5px;
  margin: 5px;
   transition: color 1s, background-size .7s, background-position .5s ease-in-out;
}
.newsTitleBox:hover {
    background-size: 100% 100%;
    background-position: 100% 100%;
    color:white;
    transition: color 1s, background-position .7s, background-size .5s ease-in-out;
  }
@keyframes spinner {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

.spinner-container {
    margin: auto;
    height: 100vh;
    padding-top: 25vh;
}

.loading-spinner {
    width: 50px;
    height: 50px;
    border: 10px solid #f3f3f3;
    /* Light grey */
    border-top: 10px solid #383636;
    /* Black */
    border-radius: 50%;
    animation: spinner 1.5s linear infinite;
}
.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}


.headerLogo {
  height: 50px;
  margin-top: 15px;
}

body {
  text-align: center;
}

.header {
  background: rgb(255, 255, 255);
  width: 100%;
  height: 70px;
  padding: 0 1.5rem;
  transition: 0.3s ease all;
}
@media (min-width: 1024px) {
  .header {
    padding: 0 3rem;
  }
}
.header__content {
  overflow: hidden;
  color: rgb(32, 32, 32);
  margin: 0 auto;
  max-width: 1920px;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
  z-index: 100;
}
.header__content__logo {
  font-size: 2rem;
  font-weight: bold;
  text-decoration: none;
  color: #fff;
}
.header__content__nav {
  top: 0;
  right: 100%;
  bottom: 0;
  width: 100%;
  height: 100vh;
  position: fixed;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  background: rgba(32, 32, 32, 0.9);
  -webkit-backdrop-filter: blur(2px);
          backdrop-filter: blur(2px);
  transform: translate(0);
  transition: 0.3s ease transform;
}
@media (min-width: 1024px) {
  .header__content__nav {
    transform: none;
    flex-direction: row;
    background: transparent;
    width: auto;
    height: 100%;
    position: static;
  }
}
.header__content__nav ul {
  list-style: none;
  padding: 0;
  display: flex;
  flex-direction: column;
  margin-bottom: 32px;
}
@media (min-width: 1024px) {
  .header__content__nav ul {
    flex-direction: row;
    align-items: center;
    margin-bottom: 0;
    margin-right: calc(0.5rem + 16px);
  }
}
.header__content__nav ul li:not(:last-child) {
  margin-bottom: 32px;
}
@media (min-width: 1024px) {
  .header__content__nav ul li:not(:last-child) {
    margin-bottom: 0;
    margin-right: 16px;
  }
}
.header__content__nav ul li a {
  text-decoration: none;
  color: inherit;
  padding: 0.75rem 1.25rem;
  border-radius: 7px;
  transition: 0.3s ease all;
}
.header__content__nav ul li a:hover {
  background: rgba(255, 255, 255, 0.1);
}
.header__content__nav ul li a:active {
  border-radius: calc(12px + 6px);
  background: linear-gradient(rgba(255, 255, 255, 0.1), rgba(255, 255, 255, 0.2));
}
.header__content__nav .btn {
  cursor: pointer;
  outline: none;
  padding: 0.75rem 1.25rem;
  border-radius: 7px;
  font-size: 1rem;
  font-family: inherit;
  background: #004f64;
  color: rgb(32, 32, 32);
  border: 1px solid transparent;
  transition: 0.3s ease all;
  margin: 0.5em 0.5em;
}
.header__content__nav .btn:hover {
  border-color: #004f64;
  background: rgba(0, 79, 100, 0.1);
  color: white;
}
.header__content__nav .btn:active {
  border-color: #004f64;
  background: linear-gradient(rgba(162, 162, 246, 0.2), rgba(162, 162, 246, 0.3));
  color: white;
}
.header__content__nav .btn__login {
  background-color: rgb(32, 32, 32);
  color: #fff;
}
.header__content__nav.isMenu {
  transform: translate(100%);
}
.header__content__toggle {
  cursor: pointer;
  display: flex;
  align-items: center;
  font-size: 2rem;
  transition: 0.3s ease all;
  position: relative;
}
.header__content__toggle:hover {
  color: white;
}
@media (min-width: 1024px) {
  .header__content__toggle {
    display: none;
  }
}

button.btn {
  color: white !important;
}

.swiper-container,
.swiper-wrapper {
  height: 100vh;
  background: green;
  display: flex;
  align-items: flex-end;
}

.swiper-wrapper {
  display: flex;
  align-items: flex-end;
}

.swiper-slide {
  display: flex;
  align-items: flex-end;
  width: auto;
  margin-right: 8px;
}
.swiper-slide img {
  max-width: 780px;
  max-height: 655px;
}

.projectInfo {
  position: relative;
  margin: 14px 0 13px 0;
  position: relative;
  z-index: -1;
  text-transform: uppercase;
}
.projectInfo h2 {
  margin: 0;
}
.projectInfo h3 {
  margin: 8px 8px 8px 0;
}
.projectInfo .category,
.projectInfo .medium {
  text-transform: none;
  opacity: 0.4;
}
.projectInfo .medium {
  border: 1px solid rgba(0, 0, 0, 0.5);
  border-radius: 100px;
  font-style: italic;
  padding: 0px 7px 1px 7px;
}

.thumbContainer {
  opacity: 1;
  transform: translateY(60%);
}
.thumbContainer img,
.thumbContainer video {
  display: block;
  position: relative;
  max-width: calc(380px + 43vh);
  max-height: calc(255px + 43vh);
}

.fadeInSlide {
  animation-name: fadeInSlide;
  animation-duration: 1.5s;
  animation-timing-function: cubic-bezier(1, 0.055, 0.32, 1);
  animation-fill-mode: forwards;
}

@keyframes fadeInSlide {
  0% {
    transform: translateY(60%);
  }
  100% {
    transform: translateY(0);
  }
}
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

body {
  height: auto;
}

* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
  font-family: 'Poppins', sans-serif;
}

h1,
h2 {
  margin: 45px;
  font-weight: 600;
}


a {
  text-decoration: none;
  color: var(--text-primary);
}

a:hover {
  color: var(--bg-terniary);
}



.avatar {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  border: none;
  object-fit: cover;
  margin-right: 10px;
}
