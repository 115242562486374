@mixin breakpoint($point) {
    @if $point ==md {

        // 768px
        @media (min-width: 1024px) {
            @content;
        }
    }
}

.headerLogo {
    height: 50px;
    margin-top: 15px;
}

body {
    text-align: center;
}

.header {
    background: rgb(255, 255, 255);
    width: 100%;
    height: 70px;
    padding: 0 1.5rem;

    @include breakpoint(md) {
        padding: 0 3rem;
    }

    transition: 0.3s ease all;

    &__content {
        overflow: hidden;
        color: rgb(32,32,32);
        margin: 0 auto;
        max-width: 1920px;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        position: relative;
        z-index: 100;

        &__logo {
            font-size: 2rem;
            font-weight: bold;
            text-decoration: none;
            color: #fff;
        }

        &__nav {
            top: 0;
            right: 100%;
            bottom: 0;
            width: 100%;
            height: 100vh;
            position: fixed;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            text-align: center;
            background: rgba(rgb(32, 32, 32), 0.9);
            backdrop-filter: blur(2px);
            transform: translate(0);
            transition: 0.3s ease transform;


            @include breakpoint(md) {
                transform: none;
                flex-direction: row;
                background: transparent;
                width: auto;
                height: 100%;
                position: static;
            }

            ul {
                list-style: none;
                padding: 0;
                display: flex;
                flex-direction: column;
                margin-bottom: 32px;

                @include breakpoint(md) {
                    flex-direction: row;
                    align-items: center;
                    margin-bottom: 0;
                    margin-right: calc(0.5rem + #{16px});
                }

                li {
                    &:not(:last-child) {
                        margin-bottom: 32px;

                        @include breakpoint(md) {
                            margin-bottom: 0;
                            margin-right: 16px;
                        }
                    }

                    a {
                        text-decoration: none;
                        color: inherit;
                        padding: 0.75rem 1.25rem;
                        border-radius: 7px;
                        transition: 0.3s ease all;

                        &:hover {
                            background: rgba(#fff, 0.1);
                        }

                        &:active {
                            border-radius: calc(#{12px} + 6px);
                            background: linear-gradient(rgba(#fff, 0.1), rgba(#fff, 0.2));
                        }
                    }
                }
            }

            .btn {
                cursor: pointer;
                outline: none;
                padding: 0.75rem 1.25rem;
                border-radius: 7px;
                font-size: 1rem;
                font-family: inherit;
                background: #004f64;
                color: rgb(32, 32, 32);
                border: 1px solid transparent;
                transition: 0.3s ease all;
                margin: 0.50em 0.50em;


                &:hover {
                    border-color: #004f64;
                    background: rgba(#004f64, 0.1);
                    color: white;
                }

                &:active {
                    border-color: #004f64;
                    background: linear-gradient(rgba(rgb(162, 162, 246), 0.2), rgba(rgb(162, 162, 246), 0.3));
                    color: white;
                }

                &__login {

                    background-color: rgb(32, 32, 32);
                    color: #fff;

                }

            }


            &.isMenu {
                transform: translate(100%);
            }
        }

        &__toggle {
            cursor: pointer;
            display: flex;
            align-items: center;
            font-size: 2rem;
            transition: 0.3s ease all;
            position: relative;

            &:hover {
                color: white;
            }

            @include breakpoint(md) {
                display: none;
            }
        }
    }
}

button.btn {
    color: white !important;
}

.swiper-containter {
}
.swiper-container,
.swiper-wrapper {
  height: 100vh;
  background: green;
  display: flex;
  align-items: flex-end;
}

.swiper-wrapper {
  display: flex;
  align-items: flex-end;
}

.swiper-slide {
  display: flex;
  align-items: flex-end;
  width: auto;
  margin-right: 8px;
  img {
    max-width: 780px;
    max-height: 655px;
  }
}

.projectInfo {
  position: relative;
  margin: 14px 0 13px 0;
  position: relative;
  z-index: -1;
  text-transform: uppercase;
  h2 {
    margin: 0;
  }
  h3 {
    margin: 8px 8px 8px 0;
  }
  .category,
  .medium {
    text-transform: none;
    opacity: 0.4;
  }
  .medium {
    border: 1px solid rgba(0, 0, 0, 0.5);
    border-radius: 100px;
    font-style: italic;
    padding: 0px 7px 1px 7px;
  }
}


.thumbContainer {
  opacity: 1;
  transform: translateY(60%);
  img,
  video {
    display: block;
    position: relative;
    max-width: calc(380px + 43vh);
    max-height: calc(255px + 43vh);
  }
}

.fadeInSlide {
  animation-name: fadeInSlide;
  animation-duration: 1.5s;
  animation-timing-function: cubic-bezier(1, 0.055, 0.32, 1);
  animation-fill-mode: forwards;
}

@keyframes fadeInSlide {
  0% {
    transform: translateY(60%);
  }
  100% {
    transform: translateY(0);
  }
}
